<template>
  <div class="flexList from-header">
    <div class="d-flex w-25" :class="{ active: pageNum > 0 }">
      <div class="data-tips flexList">
        <div class="form-num">
          <div class="form-circle mr-2">
            <span class="circle-font">1</span>
          </div>
          <span class="status" v-if="pageNum > 1"
            ><i class="iconfont icon-a-ze-checkedCopy2 text-success"></i
          ></span>
        </div>
        <div class="tip-font">
          <div class="flexList">
            <div class="fw600 tip-top">提交报名</div>
            <div class="form-line"></div>
          </div>
          <div class="desc-box">
            <!-- <div class="mt-1">开始时间：{{ data.examRecordBean.bmkssj }}</div>
            <div class="mt-1">结束时间：{{ data.examRecordBean.bmjssj }}</div> -->
<!--            <div class="mt-1">按要求提交完成所有数据并检查确认</div>-->
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex w-25" v-if="data.examRecordBean.sfxyzgjgsh" :class="{ active: pageNum > 1 }">
      <div class="data-tips flexList">
        <div class="form-num">
          <div class="form-circle mr-2">
            <span class="circle-font">2</span>
          </div>
          <span class="status" v-if="pageNum > 2 && data.zgshzt == 1"
            ><i class="iconfont icon-a-ze-checkedCopy2 text-success"></i></span
          ><span class="status" v-if="pageNum > 2 && data.zgshzt == 2"
            ><i class="iconfont icon-ze-clear text-danger"></i
          ></span>
        </div>
        <div class="tip-font">
          <div class="flexList">
            <div class="fw600 tip-top">资格审核</div>
            <div class="form-line"></div>
          </div>
          <div class="desc-box">
            <!-- <div class="mt-1">开始时间：{{ data.examRecordBean.cskssj }}</div>
            <div class="mt-1">结束时间：{{ data.examRecordBean.csjssj }}</div> -->
<!--            <div class="mt-1">等待审核人员审核</div>-->
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex w-25" v-if="data.examRecordBean.sfxyjf" :class="{ active: pageNum > 2 }">
      <div class="data-tips flexList">
        <div class="form-num">
          <div class="form-circle mr-2">

            <span class="circle-font" v-if="data.examRecordBean.sfxyzgjgsh">3</span>
            <span class="circle-font" v-if="!data.examRecordBean.sfxyzgjgsh">2</span>
          </div>
          <span class="status" v-if="pageNum >3 && data.jfzt == 1"
            ><i class="iconfont icon-a-ze-checkedCopy2 text-success"></i></span
          >
        </div>
        <div class="tip-font">
          <div class="flexList">
            <div class="fw600 tip-top">在线缴费</div>
            <div class="form-line"></div>
          </div>
          <div class="desc-box">
            <!-- <div class="mt-1">开始时间：{{ data.examRecordBean.jfkssj }}</div>
            <div class="mt-1">结束时间：{{ data.examRecordBean.jfjssj }}</div> -->
<!--            <div class="mt-1">审核成功后请尽快缴费，避免延误考试</div>-->
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex w-25" :class="{ active: pageNum > 3 }">
      <div class="data-tips flexList">
        <div class="form-num">
          <div class="form-circle mr-2">
            <span class="circle-font" v-if="data.examRecordBean.sfxyjf && data.examRecordBean.sfxyzgjgsh">4</span>
            <span class="circle-font" v-if="!data.examRecordBean.sfxyjf && data.examRecordBean.sfxyzgjgsh">3</span>
            <span class="circle-font" v-if="data.examRecordBean.sfxyjf && !data.examRecordBean.sfxyzgjgsh">3</span>
            <span class="circle-font" v-if="!data.examRecordBean.sfxyjf && !data.examRecordBean.sfxyzgjgsh">2</span>
          </div>
          <span class="status" v-if="pageNum >3 && data.zgshzt == 1"
            ><i class="iconfont icon-a-ze-checkedCopy2 text-success"></i></span
          >
        </div>
        <div class="tip-font">
          <div class="flexList">
            <div class="fw600 tip-top">完成报名</div>
          </div>
          
          <div class="desc-box">
            <!-- <div class="mt-1">开始时间：{{ data.examRecordBean.bmjssj }}</div> -->
            <!-- <div class="mt-1">结束时间：{{ data.examRecordBean.bmjssj }}</div> -->
<!--            <div class="mt-1">缴费完成，完成报名</div>-->
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="d-flex w-20" :class="{ active: pageNum > 4 }">
      <div class="data-tips flexList">
        <div class="form-num">
          <div class="form-circle mr-2">
            <span class="circle-font">5</span>
          </div>
        </div>
        <div class="tip-font">
          <div class="flexList">
            <div class="fw600 tip-top">打印准考证、考试</div>
          </div>
          <div class="form-desc-box mt-1">
            请尽快在考试前打印准考证，参加考试
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import $ from "jquery";
import { format } from "@/libs/common";

export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      pageNum: null,
    };
  },
  created() {
    let data = this.data;
    if (data.jfzt == 1 || (!data.examRecordBean.sfxyjf&&data.zgshzt == 1)) {
      this.pageNum = 4;
      return;
    }
    if (data.zgshzt == 1) {
      this.pageNum = 3;
      return;
    }
    if (data.zgshzt != 0 && data.zgshzt != null) {
      this.pageNum = 2;
      return;
    }
    if (data.sfzstj && data.zgshzt == 0) {
      this.pageNum = 2;
      return;
    }
    if (!data.sfzstj && data.zgshzt == 0) {
      this.pageNum = 1;
      return;
    }
  },
};
</script>
<style>
@import "~@/assets/css/common.css";
@import "~@/assets/css/enroll-form.css";
.from-header .data-tips.flexList {
  align-items: flex-start;
}

.from-header.flexList {
  /* justify-content: space-between; */
  align-items: flex-start;
}
.tip-font .flexList {
  width: 100%;
}
.active .form-circle {
  background-color: #1990ff;
  border-color: #2f8ce6;
  color: #ffffff;
}

.active .form-line {
  border-color: #1990ff;
}
.card-title {
  display: block;
}
.desc-box {
  width: 100%;
  font-size: 14px;
  color: rgb(140, 140, 140);
}
.status {
  position: absolute;
  right: 0px;
  top: -5px;
  font-size: 20px !important;
}
.from-header .data-tips{
  height: unset !important;
}
</style>
